import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//获取红包雨列表
export const getHongBaoYuList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/hby/list",
  });

//保存红包雨
export const saveHongBaoYu = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/hby/save",
  });

//设置红包雨状态
export const setHongBaoYuStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/hby/status/set",
  });

//获取抽奖活动
export const getLotteryActivity = (data: any) =>
  request({
    method: "post",
    url: "/adm/active/getBanner",
  });

//添加/更新活动
export const updataLotteryActivity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/active/setBanner",
  });

//保存/添加活动
export const saveLiveActivity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/activity/save",
  });

//获取直播活动列表
export const getLiveActivityList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/activity/list",
  });

//添加自定义白名单
export const addLiveActivityWhiteUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/activity/white/user/add",
  });

//设置活动状态
export const setLiveActivityStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/activity/status/set",
  });

//设置活动直播状态
export const setLiveActivityLiveStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/activity/live/status/set",
  });

//获取直播用户列表
export const getLiveRoomUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/room/user/list",
  });

//直播间用户设置
export const setLiveRoomUserBlock = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/room/user/block",
  });

//获取直播消息列表
export const getLiveRoomMessageList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/room/msg/list",
  });

//获取活动关联用户列表
export const getLiveRoomActivityUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/live/activity/user/list",
  });

//女神大赛列表
export const getGoddessContestList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/list",
  });

//保存女神大赛活动
export const saveGoddessContest = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/save",
  });

//导出女神大赛列表
export const exportGoddessContestList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/goddess/contest/export`,
  });

//设置女神大赛活状态
export const setGoddessContestStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/status/set",
  });

//获取女神大赛投标列表
export const getGoddessContestVoteList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/vote/list",
  });

//导出女神大赛投标列表
export const exportGoddessContesVotetList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/goddess/contest/vote/export`,
  });

//获取博主列表
export const getGoddessContestBloggerList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/blogger/list",
  });

//获取组合预测
export const getGoddessContestForecastList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/forecast",
  });

//设置冠军组合
export const setGoddessContestForecast = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/winner/seq/set",
  });

//导出博主列表
export const getGoddessContestBloggerExport = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/goddess/contest/blogger/export`,
  });

//保存博主
export const saveGoddessContestBlogger = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/blogger/save",
  });

//获取奖励列表
export const getGoddessContestAwardList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/award/list",
  });

//获取返还列表
export const getGoddessContestRebateList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/rebate/list",
  });

//获取收支预览
export const getGoddessContestSummary = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddess/contest/summary",
  });

//女神大赛活动列表
export const getGoddessContestActive = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddessContest/get/active",
  });

//女神大赛活动列表
export const setGoddessContestActive = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddessContest/set/active",
  });

//添加&编辑参赛博主
export const setGoddessContestBlogger = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddessContest/set/blogger",
  });

//赛事历史记录
export const getGoddessContestHistory = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddessContest/history",
  });

//玩家投票记录
export const getGoddessContestVoteRecord = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/goddessContest/vote/record",
  });

//H5活动列表
export const getH5ActivityList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/h5_activity/list",
  });

//下拉道具类型
export const getLedgerAll = () =>
  request({
    method: "post",
    url: "/adm/ledger/propconf/all",
  });

//添加H5活动
export const addH5Activity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/h5_activity/add",
  });

//编辑活动
export const updateH5Activity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/h5_activity/update",
  });

//删除H5活动
export const deleteH5Activity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/h5_activity/delete",
  });

//获取H5活动排行
export const getH5ActivityRank = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/h5_activity/rank",
  });

//重新计算排行榜
export const reCalH5Activity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/h5_activity/re_cal",
  });

//礼物墙列表
export const giftWallList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/gift_wall/list",
  });

//删除礼物墙
export const deleteGiftWall = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/gift_wall/delete",
  });

//保存礼物墙
export const saveGiftWall = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/gift_wall/save",
  });

//礼物墙列表
export const giftWallPropAll = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/gift_wall/prop/all",
  });
