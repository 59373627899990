
//公共库
import axios from "axios";
import { VueEditor } from "vue2-editor";
import { MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import { getLedgerAll } from "@/api/request/item";
import Pagination from "@/comp/Pagination/index.vue";
import { countryNoComList, parseTime } from "@/utils";
import {
  saveGoddessContest,
  getGoddessContestList,
  setGoddessContestStatus,
  exportGoddessContestList,
  getGoddessContestSummary,
  setGoddessContestForecast,
  getGoddessContestVoteList,
  saveGoddessContestBlogger,
  getGoddessContestAwardList,
  getGoddessContestRebateList,
  getGoddessContestBloggerList,
  exportGoddessContesVotetList,
  getGoddessContestForecastList,
  getGoddessContestBloggerExport,
} from "@/api/request/activity";
import { getAppBaseApi, getHeaders } from "@/api/request";

//组件
@Component({
  name: "GoddessContest",
  components: {
    VueEditor,
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private propList: any[] = [];
  private checked: boolean = true;
  private countryList: any[] = [];
  private listLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    //临时数据
    time: [],

    //页面数据
    page: 1, // 当前页
    page_size: 10, // 页大小

    //请求数据
    status: -1, //状态
    country: "", //归属国家
    end_time: "", //结束时间
    begin_time: "", //开始时间
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //状态列表
  private statusList: any[] = [
    { status: -1, name: "全部状态" },
    { status: 0, name: "草稿" },
    { status: 1, name: "开启" },
    { status: 2, name: "关闭" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取道具列表
    this.getLedgerAll();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = this.listQuery.time[1];
      this.listQuery.begin_time = this.listQuery.time[0];
    } else {
      this.listQuery.end_time = "";
      this.listQuery.begin_time = "";
    }

    //获取数据
    const { data } = await getGoddessContestList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //数据赋值
    this.form = {
      //临时数据
      auto_settle_str: "1",
      hdcoin_award_str: "0",
      joined_count_str: "0",
      winner_include_arr: [],

      //基本信息
      id: 0, //ID
      name: "", //名称
      status: 0, //状态
      country: "", //归属国家
      user_faq: "", // 用户规则说明
      joined_count: 0, //参与人次
      hdcoin_award: 0, //互动币奖励
      auto_settle: 1, // 是否自动结算
      blogger_faq: "", // 博主规则说明
      winner_include: "", //冠军博主ID

      //新增参数
      prop_id: 0, //安慰奖道具ID
      settle_rate: 0.01, //结算胜出比例
      return_rate: 0, //结算用户返还比例

      //时间
      end_time: "", // 结束时间
      begin_time: "", // 开始时间
    };

    //数据赋值
    this.showType = 0;

    //显示界面
    this.dialogVisible = true;
  }

  //获取道具列表
  private async getLedgerAll() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const res = await getLedgerAll();

    //数据赋值
    this.propList = res.data;
    this.propList.unshift({ propid: 0, propname: "请选择安慰奖道具" });
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = this.listQuery.time[1];
      this.listQuery.begin_time = this.listQuery.time[0];
    } else {
      this.listQuery.end_time = "";
      this.listQuery.begin_time = "";
    }

    //获取数据
    const { data } = await exportGoddessContestList(this.listQuery);

    //保存数据
    saveAs(data, "女神大赛列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      auto_settle_str: String(row.auto_settle),
      hdcoin_award_str: String(row.hdcoin_award),
      joined_count_str: String(row.joined_count),
      winner_include_arr: row.winner_include == "" ? [] : String(row.winner_include).split("|"),

      //基本信息
      id: row.id, //ID
      name: row.name, //名称
      status: row.status, //状态
      country: row.country, //归属国家
      user_faq: row.user_faq, // 用户规则说明
      joined_count: row.joined_count, //参与人次
      hdcoin_award: row.hdcoin_award, //互动币奖励
      auto_settle: row.auto_settle, // 是否自动结算
      blogger_faq: row.blogger_faq, // 博主规则说明
      winner_include: row.winner_include, //冠军博主ID

      //新增参数
      prop_id: row.prop_id, //安慰奖道具ID
      settle_rate: Math.max(0.01, row.settle_rate), //结算胜出比例
      return_rate: row.return_rate, //结算用户返还比例

      //时间
      end_time: row.end_time, // 结束时间
      begin_time: row.begin_time, // 开始时间
    };

    //数据赋值
    this.showType = 1;

    //显示界面
    this.dialogVisible = true;
  }

  //处理详情
  private handleDetail(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      auto_settle_str: String(row.auto_settle),
      hdcoin_award_str: String(row.hdcoin_award),
      joined_count_str: String(row.joined_count),
      winner_include_arr: row.winner_include == "" ? [] : String(row.winner_include).split("|"),

      //基本信息
      id: row.id, //ID
      name: row.name, //名称
      status: row.status, //状态
      country: row.country, //归属国家
      user_faq: row.user_faq, // 用户规则说明
      joined_count: row.joined_count, //参与人次
      hdcoin_award: row.hdcoin_award, //互动币奖励
      auto_settle: row.auto_settle, // 是否自动结算
      blogger_faq: row.blogger_faq, // 博主规则说明
      winner_include: row.winner_include, //冠军博主ID

      //新增参数
      prop_id: row.prop_id, //安慰奖道具ID
      settle_rate: Math.max(0.01, row.settle_rate), //结算胜出比例
      return_rate: row.return_rate, //结算用户返还比例

      //时间
      end_time: row.end_time, // 结束时间
      begin_time: row.begin_time, // 开始时间
    };

    //数据赋值
    this.showType = 2;

    //显示界面
    this.dialogVisible = true;
  }

  //处理状态
  private async handleStatus(row: any) {
    //判断提示
    if (row.status == 1) {
      MessageBox.confirm("关闭将更新活动结束时间，后台自动进入结算。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          //显示等待
          this.listLoading = true;

          //隐藏等待
          setTimeout(() => {
            this.listLoading = false;
          }, 1.5 * 1000);

          //设置状态
          await setGoddessContestStatus({ id: row.id, status: 2 });

          //隐藏等待
          setTimeout(() => {
            //加载列表
            this.getList();
          }, 0.5 * 1000);
        })
        .catch((action) => {});
    } else {
      //显示等待
      this.listLoading = true;

      //隐藏等待
      setTimeout(() => {
        this.listLoading = false;
      }, 1.5 * 1000);

      //设置状态
      await setGoddessContestStatus({ id: row.id, status: 1 });

      //隐藏等待
      setTimeout(() => {
        //加载列表
        this.getList();
      }, 0.5 * 1000);
    }
  }

  //获取时间
  private getTimeStr(time: number): any {
    return parseTime(time * 1000);
  }

  //处理投票列表
  private handleVoteList(row: any): void {
    //数据赋值
    this.voteListQuery = {
      //页面数据
      page: 1, //当前页
      page_size: 10, //页大小

      //临时数据
      user_id_str: "",
      blogger_id_str: "",

      //基本数据
      user_id: 0, //用户ID
      blogger_id: 0, //博主ID
      contest_id: row.id, //大赛ID
      type: 0, //操作类型：0-全部，1-投票，2-点赞
      sub_type: 0, //值类型：0-全部，1-免费，2-钻石，3-系统
    };

    //显示界面
    this.voteDialogVisible = true;

    //获取列表
    this.getVoteList();
  }

  //获取道具名称
  private getGiftName(id: number): string {
    //判断返回
    if (id == 0) {
      return "";
    } else {
      for (var i: number = 0; i < this.propList.length; i++) {
        if (id == this.propList[i].propid) {
          return this.propList[i].propname;
        }
      }
    }

    return "";
  }

  //处理博主列表
  private handleBloggerList(row: any): void {
    //数据赋值
    this.bloggerListQuery = {
      //页面数据
      page: 1, // 当前页
      page_size: 10, // 页大小

      //临时数据
      blogger_id_str: "",

      //基本数据
      nick_name: "", //昵称
      blogger_id: 0, // 博主ID
      status: row.status, //状态
      contest_id: row.id, // 大赛ID
    };

    //显示界面
    this.bloggerDialogVisible = true;

    //获取列表
    this.getBloggerList();
  }

  //处理奖励列表 -->
  private handleAwardList(row: any): void {
    //数据赋值
    this.awardListQuery = {
      //页面数据
      page: 1, // 当前页
      page_size: 10, // 页大小

      //临时数据
      user_id_str: "",

      //基本数据
      userr_id: 0, // 博主ID
      contest_id: row.id, // 大赛ID
    };

    //显示界面
    this.awardDialogVisible = true;

    //获取列表
    this.getAwardList();
  }

  //处理返还列表
  private handleRebateList(row: any): void {
    //数据赋值
    this.rebateListQuery = {
      //页面数据
      page: 1, // 当前页
      page_size: 10, // 页大小

      //临时数据
      user_id_str: "",

      //基本数据
      userr_id: 0, // 博主ID
      contest_id: row.id, // 大赛ID
    };

    //显示界面
    this.rebateDialogVisible = true;

    //获取列表
    this.getRebateList();
  }

  //处理收支概览
  private handleContestSummary(row: any): void {
    //数据赋值
    this.summaryQuery = {
      id: row.id, // 大赛ID
    };

    //显示界面
    this.summaryDialogVisible = true;

    //获取收支概览
    this.getSummary();
  }

  //处理组合预测
  private handleContestForecast(row: any): void {
    //数据赋值
    this.forecastListQuery = {
      id: row.id, // 大赛ID
    };

    //显示界面
    this.forecastDialogVisible = true;

    //获取收支概览
    this.getForecastList();
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //--------------------------------------- 创建/编辑/详情 ---------------------------------------
  //定义变量
  private tagInput = "";
  private showType: number = 0;
  private getHeads = getHeaders();
  private getAction = this.getApi();
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //表单数据
  private form: any = {
    //临时数据
    auto_settle_str: "0",
    hdcoin_award_str: "0",
    joined_count_str: "0",
    winner_include_arr: [],

    //基本信息
    id: 0, //ID
    name: "", //名称
    status: 0, //状态
    country: "", //归属国家
    joined_count: 0, //参与人次
    hdcoin_award: 0, //互动币奖励
    user_faq: "", // 用户规则说明
    auto_settle: 1, // 是否自动结算
    blogger_faq: "", // 博主规则说明
    winner_include: "", //冠军博主ID

    //新增参数
    prop_id: 0, //安慰奖道具ID
    settle_rate: 0.01, //结算胜出比例
    return_rate: 0, //结算用户返还比例

    //时间
    end_time: "", // 结束时间
    begin_time: "", // 开始时间
  };

  //添加ID
  private addTag(): void {
    //判断ID
    if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
      this.form.winner_include_arr.push(this.tagInput.trim());
      this.tagInput = ""; // 清空输入框
    } else {
      this.$message.error("请输入正确形式的id");
      this.tagInput = "";
    }
  }

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏按钮
    this.dialogVisible = false;
  }

  //处理保存
  private async handlePush() {
    //判断返回
    if (this.form.name == "") return this.$message.error("请输入名称");
    if (this.form.end_time == "") return this.$message.error("选择结束时间");
    if (this.form.begin_time == "") return this.$message.error("选择开始时间");
    if (this.form.user_faq == "") return this.$message.error("请输入用户规则说明");
    if (this.form.blogger_faq == "") return this.$message.error("请输入博主规则说明");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.winner_include = "";
    this.form.auto_settle = Number(this.form.auto_settle_str);
    this.form.joined_count = Number(this.form.joined_count_str);
    this.form.hdcoin_award = Number(this.form.hdcoin_award_str);
    for (var i: number = 0; i < this.form.winner_include_arr.length; i++) {
      this.form.winner_include += this.form.winner_include_arr[i] + "|";
    }
    if (this.form.winner_include.length > 0) this.form.winner_include = String(this.form.winner_include).substring(0, String(this.form.winner_include).length - 1);

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 1.5 * 1000);

    await saveGoddessContest(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏界面
      this.dialogVisible = false;

      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //处理移除
  private removeTag(index: number): void {
    this.form.winner_include_arr.splice(index, 1);
  }

  //处理图片添加
  private async handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: Function) {
    //数据处理
    const formData = new FormData();
    formData.append("files", file);

    //上传图片
    var config: any = {};
    config.headers = this.getHeads;
    const res = await axios.post(this.getAction, formData, config);

    //数据赋值
    let url: string = res.data.data.images[0];

    //插入图片
    Editor.insertEmbed(cursorLocation, "image", url);

    //刷新编辑器
    resetUploader();
  }

  //--------------------------------------- 投票列表 ---------------------------------------
  //定义变量
  private voteList: any[] = [];
  private voteTotal: number = 0;
  private voteDialogVisible: boolean = false;
  private voteDialogLoading: boolean = false;

  //列表请求参数
  private voteListQuery: any = {
    //页面数据
    page: 1, // 当前页
    page_size: 10, // 页大小

    //临时数据
    user_id_str: "",
    blogger_id_str: "",

    //基本数据
    user_id: 0, // 用户ID
    contest_id: 1, // 大赛ID
    blogger_id: 0, // 博主ID
    type: 0, //操作类型：0-全部，1-投票，2-点赞
    sub_type: 0, //值类型：0-全部，1-免费，2-钻石，3-系统
  };

  //操作类型
  private voteTypeList: any[] = [
    { status: 0, name: "全部" },
    { status: 1, name: "投票" },
    { status: 2, name: "点赞" },
  ];

  //冲值类型
  private voteSubTypeList: any[] = [
    { status: 0, name: "全部" },
    { status: 1, name: "免费" },
    { status: 2, name: "钻石" },
    { status: 3, name: "系统" },
  ];

  //处理取消
  private btnVoteCancel(): void {
    //隐藏界面
    this.voteDialogVisible = false;
  }

  //获取列表
  private async getVoteList() {
    //显示等待
    this.voteDialogLoading = true;

    //数据赋值
    this.voteListQuery.user_id = Number(this.voteListQuery.user_id_str);
    this.voteListQuery.blogger_id = Number(this.voteListQuery.blogger_id_str);

    //获取数据
    const { data } = await getGoddessContestVoteList(this.voteListQuery);

    //数据赋值
    this.voteList = data.list;
    this.voteTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.voteDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleVoteFilter(): void {
    //数据赋值
    this.voteListQuery.page = 1;

    //获取列表
    this.getVoteList();
  }

  //处理查询
  private async handleVoteExport() {
    //数据赋值
    this.voteListQuery.page = 1;

    //显示等待
    this.voteDialogLoading = true;

    //数据赋值
    this.voteListQuery.user_id = Number(this.voteListQuery.user_id_str);
    this.voteListQuery.blogger_id = Number(this.voteListQuery.blogger_id_str);

    //获取数据
    const { data } = await exportGoddessContesVotetList(this.voteListQuery);

    //保存数据
    saveAs(data, "女神大赛列表");

    //隐藏等待
    setTimeout(() => {
      this.voteDialogLoading = false;
    }, 0.5 * 1000);
  }

  //--------------------------------------- 博主列表 ---------------------------------------
  //定义变量
  private bloggerList: any[] = [];
  private bloggerTotal: number = 0;
  private bloggerDialogVisible: boolean = false;
  private bloggerDialogLoading: boolean = false;

  //列表请求参数
  private bloggerListQuery: any = {
    //页面数据
    page: 1, // 当前页
    page_size: 10, // 页大小

    //临时数据
    blogger_id_str: "",

    //基本数据
    status: 0, //状态
    nick_name: "", //昵称
    contest_id: 1, // 大赛ID
    blogger_id: 0, // 博主ID
  };

  //获取博主列表
  private async getBloggerList() {
    //显示等待
    this.bloggerDialogLoading = true;

    //数据赋值
    this.bloggerListQuery.blogger_id = Number(this.bloggerListQuery.blogger_id_str);

    //获取数据
    const { data } = await getGoddessContestBloggerList(this.bloggerListQuery);

    //数据赋值
    this.bloggerList = data.list;
    this.bloggerTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.bloggerDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理取消
  private btnBloggerCancel(): void {
    //隐藏界面
    this.bloggerDialogVisible = false;
  }

  //处理添加博主
  private async handleAddBlogger() {
    //数据赋值
    this.bloggerForm = {
      //临时数据
      system_hots_str: "",
      system_votes_str: "",
      system_likes_str: "",
      odds_str: "",
      daily_id_str: "",
      blogger_id_str: "",

      //数据
      id: 0, //参与ID
      odds: 0, //赔率，浮点数， 必需
      daily_id: 0, //作品ID, 必需
      blogger_id: 0, //博主ID, 必需
      system_hots: 0, //新增系统热度值，0-无修改
      system_votes: 0, //新增系统投票数，0-无修改
      system_likes: 0, //新增系统点赞数，0-无修改
      contest_id: this.bloggerListQuery.contest_id, //大赛ID，必需
    };

    //显示界面
    this.addBloggerDialogVisible = true;
  }

  //导出博主列表
  private async handleExportBlogger() {
    //显示等待
    this.bloggerDialogLoading = true;

    //数据赋值
    this.bloggerListQuery.blogger_id = Number(this.bloggerListQuery.blogger_id_str);

    //获取数据
    const { data } = await getGoddessContestBloggerExport(this.bloggerListQuery);

    //保存数据
    saveAs(data, "女神大赛博主列表(大赛ID:" + this.bloggerListQuery.contest_id + ")");

    //隐藏等待
    setTimeout(() => {
      this.bloggerDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleBloggerFilter(): void {
    //数据赋值
    this.bloggerListQuery.page = 1;

    //获取列表
    this.getBloggerList();
  }

  //冠军类型
  private getWinStr(win: number): string {
    if (win == 1) {
      return "才艺冠军";
    } else if (win == 2) {
      return "才艺亚军";
    } else if (win == 3) {
      return "才艺季军";
    } else if (win == 99) {
      return "人气冠军";
    } else if (win == 199) {
      return "才艺冠军+人气冠军";
    } else if (win == 299) {
      return "才艺亚军+人气冠军";
    } else if (win == 399) {
      return "才艺季军+人气冠军";
    }

    return "";
  }

  //处理编辑
  private handleEditBlogger(row: any): void {
    //数据赋值
    this.bloggerForm = {
      //临时数据
      system_hots_str: "",
      system_votes_str: "",
      system_likes_str: "",
      odds_str: String(row.odds),
      daily_id_str: String(row.daily_id),
      blogger_id_str: String(row.blogger_id),

      //数据
      id: row.id, //参与ID
      odds: row.odds, //赔率，浮点数， 必需
      system_hots: 0, //新增系统热度值，0-无修改
      system_votes: 0, //新增系统投票数，0-无修改
      system_likes: 0, //新增系统点赞数，0-无修改
      contest_id: row.contest_id, //大赛ID，必需
      blogger_id: row.blogger_id, //博主ID, 必需
      daily_id: row.daily_id, //作品ID, 必需
    };

    //显示界面
    this.addBloggerDialogVisible = true;
  }

  //--------------------------------------- 添加博主 ---------------------------------------
  //定义变量
  private addBloggerDialogVisible: boolean = false;
  private addBloggerDialogLoading: boolean = false;

  //博主添加
  private bloggerForm: any = {
    //临时数据
    odds_str: "",
    daily_id_str: "",
    blogger_id_str: "",
    system_hots_str: "",
    system_votes_str: "",
    system_likes_str: "",
    auto_likes_str: "",

    //数据
    id: 0, //参与ID
    odds: 2, //赔率，浮点数， 必需
    contest_id: 1, //大赛ID，必需
    blogger_id: 1000, //博主ID, 必需
    daily_id: 119, //作品ID, 必需
    system_votes: 0, //新增系统投票数，0-无修改
    system_likes: 0, //新增系统点赞数，0-无修改
    system_hots: 0, //新增系统热度值，0-无修改
    auto_likes: 0, //新增自动系统点赞数，0-无修改
  };

  //添加博主
  private async handleAddBloggerPush() {
    //判断返回
    if (Number(this.bloggerForm.odds_str) == 0) return this.$message.error("赔率 > 0");
    if (Number(this.bloggerForm.daily_id_str) == 0) return this.$message.error("请输入作品ID");
    if (Number(this.bloggerForm.blogger_id_str) == 0) return this.$message.error("请输入博主ID");

    //显示等待
    this.addBloggerDialogLoading = true;

    //数据赋值
    this.bloggerForm.odds = Number(this.bloggerForm.odds_str);
    this.bloggerForm.daily_id = Number(this.bloggerForm.daily_id_str);
    this.bloggerForm.blogger_id = Number(this.bloggerForm.blogger_id_str);
    this.bloggerForm.system_hots = Number(this.bloggerForm.system_hots_str);
    this.bloggerForm.system_votes = Number(this.bloggerForm.system_votes_str);
    this.bloggerForm.system_likes = Number(this.bloggerForm.system_likes_str);
    this.bloggerForm.auto_likes = Number(this.bloggerForm.auto_likes_str);

    //隐藏等待
    setTimeout(() => {
      this.addBloggerDialogLoading = false;
    }, 1.5 * 1000);

    await saveGoddessContestBlogger(this.bloggerForm);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.addBloggerDialogLoading = false;

      //隐藏界面
      this.addBloggerDialogVisible = false;

      //获取列表
      this.getBloggerList();
    }, 0.5 * 1000);
  }

  //处理取消
  private btnAddBloggerCancel(): void {
    //隐藏界面
    this.addBloggerDialogVisible = false;
  }

  //--------------------------------------- 奖励列表 ---------------------------------------
  //定义变量
  private awardList: any[] = [];
  private awardTotal: number = 0;
  private awardDialogVisible: boolean = false;
  private awardDialogLoading: boolean = false;

  //列表请求参数
  private awardListQuery: any = {
    //页面数据
    page: 1, // 当前页
    page_size: 10, // 页大小

    //临时数据
    user_id_str: "",

    //基本数据
    user_id: 0, //用户ID
    contest_id: 1, // 大赛ID
  };

  //获取奖励列表
  private async getAwardList() {
    //显示等待
    this.awardDialogLoading = true;

    //数据赋值
    this.awardListQuery.user_id = Number(this.awardListQuery.user_id_str);

    //获取数据
    const { data } = await getGoddessContestAwardList(this.awardListQuery);

    //数据赋值
    this.awardList = data.list;
    this.awardTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.awardDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理取消
  private btnAwardCancel(): void {
    //隐藏界面
    this.awardDialogVisible = false;
  }

  //处理查询
  private handleAwardFilter(): void {
    //数据赋值
    this.awardListQuery.page = 1;

    //获取列表
    this.getAwardList();
  }

  //--------------------------------------- 返还列表 ---------------------------------------
  //定义变量
  private rebateList: any[] = [];
  private rebateTotal: number = 0;
  private rebateDialogVisible: boolean = false;
  private rebateDialogLoading: boolean = false;

  //列表请求参数
  private rebateListQuery: any = {
    //页面数据
    page: 1, // 当前页
    page_size: 10, // 页大小

    //临时数据
    user_id_str: "",

    //基本数据
    user_id: 0, //用户ID
    contest_id: 1, // 大赛ID
  };

  //获取返还列表
  private async getRebateList() {
    //显示等待
    this.rebateDialogLoading = true;

    //数据赋值
    this.rebateListQuery.user_id = Number(this.rebateListQuery.user_id_str);

    //获取数据
    const { data } = await getGoddessContestRebateList(this.rebateListQuery);

    //数据赋值
    this.rebateList = data.list;
    this.rebateTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.rebateDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理取消
  private btnRebateCancel(): void {
    //隐藏界面
    this.rebateDialogVisible = false;
  }

  //处理查询
  private handleRebateFilter(): void {
    //数据赋值
    this.rebateListQuery.page = 1;

    //获取列表
    this.getRebateList();
  }

  //--------------------------------------- 组合预测 ---------------------------------------
  //定义变量
  private forecastList: any[] = [];
  private forecastDialogVisible: boolean = false;
  private forecastDialogLoading: boolean = false;

  //列表请求参数
  private forecastListQuery: any = {
    id: 1, // 大赛ID
  };

  //获取返还列表
  private async getForecastList() {
    //显示等待
    this.forecastDialogLoading = true;

    //隐藏等待
    setTimeout(() => {
      this.forecastDialogLoading = false;
    }, 1 * 1000);

    //获取数据
    const { data } = await getGoddessContestForecastList(this.forecastListQuery);

    //数据赋值
    this.forecastList = data;

    //隐藏等待
    setTimeout(() => {
      this.forecastDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理取消
  private btnForecastCancel(): void {
    //隐藏界面
    this.forecastDialogVisible = false;
  }

  //设置冠军组合
  private async setForecast(row: any) {
    //显示等待
    this.forecastDialogLoading = true;

    //获取数据
    await setGoddessContestForecast({ contest_id: this.forecastListQuery.id, winner_seq: row.seq, winner_ids: row.winner_ids });

    //隐藏等待
    setTimeout(() => {
      //重新获取列表
      this.getForecastList();
    }, 0.5 * 1000);
  }

  //获取赔率
  private getOdds(row: any, type: number): string {
    //数据赋值
    for (var i: number = 0; i < row.winners.length; i++) {
      if (row.winners[i].win == String(type)) {
        return String(row.winners[i].odds);
      }
    }

    return "";
  }

  //获取胜出博主
  private getWinBlogger(row: any, type: number): string {
    //数据赋值
    for (var i: number = 0; i < row.winners.length; i++) {
      if (row.winners[i].win == String(type)) {
        return row.winners[i].nick_name;
      }
    }

    return "";
  }

  //获取钻石票数
  private getDiamondVotes(row: any, type: number): string {
    //数据赋值
    for (var i: number = 0; i < row.winners.length; i++) {
      if (row.winners[i].win == String(type)) {
        return String(row.winners[i].diamond_votes);
      }
    }

    return "";
  }

  //获取免费票数
  private getFreeVotes(row: any, type: number): string {
    //数据赋值
    for (var i: number = 0; i < row.winners.length; i++) {
      if (row.winners[i].win == String(type)) {
        return String(row.winners[i].free_votes);
      }
    }

    return "";
  }

  //获取系统票数
  private getSystemVotes(row: any, type: number): string {
    //数据赋值
    for (var i: number = 0; i < row.winners.length; i++) {
      if (row.winners[i].win == String(type)) {
        return String(row.winners[i].system_votes);
      }
    }

    return "";
  }

  //获取总投票数
  private getTotalVotes(row: any, type: number): string {
    //数据赋值
    for (var i: number = 0; i < row.winners.length; i++) {
      if (row.winners[i].win == String(type)) {
        return String(row.winners[i].total_votes);
      }
    }

    return "";
  }

  //--------------------------------------- 收支预览 ---------------------------------------
  //定义变量
  private summaryData: any = {};
  private summaryDialogVisible: boolean = false;
  private summaryDialogLoading: boolean = false;

  //列表请求参数
  private summaryQuery: any = {
    id: 1, // 大赛ID
  };

  //获取收支预览
  private async getSummary() {
    //显示等待
    this.summaryDialogLoading = true;

    //获取数据
    const { data } = await getGoddessContestSummary(this.summaryQuery);

    //数据赋值
    this.summaryData = data;

    //隐藏等待
    setTimeout(() => {
      this.summaryDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理取消
  private btnSummaryCancel(): void {
    //隐藏界面
    this.summaryDialogVisible = false;
  }
}
